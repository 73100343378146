import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext,
} from "react";
import { useHistory } from 'react-router-dom';
import { Callback } from "./page";
import { IESConfig, IESOptions } from "./constants/IESConfig";
import {
  IESAuthUtilV2,
  abilityBuilder,
  useNetworkResponse,
  AbilityContext,
  I18nContext,
  ConfirmPopUp,
  ProgressPopUp,
  ProgressPopUpV2,
  ToastMsg,
  ErrorPopUp,
  PageNotConfigured,
  UserNotEntitled
} from "common-components-spa";
import { delay, map } from "rxjs/operators";
import { Loader } from "./components/loader/Loader";
import ExploreLessonsLoader from './components/explore-lessons/ExploreLessonsLoader';
import {
  setAuthToken,
  setUserIdHeader,
  getUserAbilities,
  getHeaderInfo,
  getSPAsInRegion,
  getNotifications,
  getOrgDetailsByUserV2,
  getTierInformation,
  getPearsonAdmin,
  getUserPlatform,
  getAppdrawer
} from "./network/ApiService";
import { useAppcues } from './hooks/useAppcues'
import { footerLinks, MAT_PLATFORM_NAME } from "./constants/Config";
import { INSTRUCTOR, ADMIN, LEARNER } from "./constants/Appcues";
import { useLocation} from 'react-router-dom';

export const AppContext = React.createContext(null);

const locale = navigator.language || "en-US";
const HTMLEntities = require('html-entities');

const Entities = ('AllHtmlEntities' in HTMLEntities) ? HTMLEntities.AllHtmlEntities : function () {
  this.encode = HTMLEntities.encode;
  this.decode = HTMLEntities.decode;
  this.decodeEntity = HTMLEntities.decodeEntity;

  return this;
}
const entities = new Entities();

const defaultToastObj = {
  title: "",
  msg: "",
  styleClass: "",
  delay: 0,
  autoHide: false,
  toastImg: "",
  show: false,
};

const defaultErrorPopupState = {
  heading: "",
  info: "",
  btnName: "",
  btnStyle: false,
  cancelBtnName: "",
  image: "",
  subInfo: "",
  renderExtraElement: () => { },
};

const defaultProgressPopupState = {
  done: false,
  waitingMsg: "",
  itemName: "",
  type: "",
  loadingImage: "",
  animationDuration: 4,
  onSuccess: () => { },
  status: null,
};

const defaultProgressPopupV2State = {
  isOpen: false,
  animationDuration: 4,
  loadingBg: "",
  info: "",
  subInfo: "",
  progressDone: false,
  onProgressBarFilled: "",
  progressErrorInfo: "",
  progressInfo: "",
  showProgressBar: "",
  propgresstypeIcon: "",
  isCloseBtn: false,
  showSuccessImage: false,
};

const defaultConfirmPopupState = {
  isModalshow: false,
  title: "",
  dialogMsg: "",
  positiveButtonText: "",
  positiveButtonHandle: () => { },
  negativeButtonText: "",
  negativeButtonHandle: () => { },
  positiveButtonStyleClass: "",
  negativeButtonStyleClass: "",
  subInfo: "",
};


const getErrorComponent = (status) => {
  if (status == 404) {
    return <PageNotConfigured />;
  } else if (status == 403) {
    return <div className="user-not-entitled-wrapper">
      <UserNotEntitled />
    </div>;
  } else {
    return '';
  }
}

const emptyAbilities = abilityBuilder([]);

const App = (props) => {
  const [isTokenExist, setIsTokenExist] = useState(false);
  const [toast, setToast] = useState(defaultToastObj);
  const [headerDetails, setHeaderDetails] = useState({}); //useState(null);
  const [footerDetails, setFooterDetails] = useState(footerLinks);
  const [urlApplicationId, setUrlApplicationId] = useState(null);
  const [errorPopupState, setErrorPopupState] = useState(
    defaultErrorPopupState
  );
  const [progressPopupV2State, setProgressPopupV2State] = useState(
    defaultProgressPopupV2State
  );
  const [progressPopupState, setProgressPopupState] = useState(
    defaultProgressPopupState
  );
  const [confirmPopupState, setConfirmPopupState] = useState(
    defaultConfirmPopupState
  );
  const [errorPopupVisibility, setErrorPopupVisibility] = useState(false);
  const [iesSession, setIesSession] = useState(null);
  const [userName, setUserName] = useState("");
  const [defaultSpaId, setdefaultSpaID] = useState("");
  const [userAbilities, setUserAbilities] = useState(emptyAbilities);
  const [availableSPAs, setAvailableSPAs] = useState([]);
  const [isOnlyStudentHomeSpa, setIsOnlyStudentHomeSpa] = useState(false);

  const {
    translate: userTranslate,
    loadUserTranslation,
    dispatch,
    translateHTML,
  } = useContext(I18nContext);
  const [translate, setTranslate] = useState(() => userTranslate);
  const [wrapperLevelErrorPage, setWrapperLevelErrorPage] = useState(null);
  const [spaDetailsList, setSpaDetailsList] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [organization, setOrganization] = useState(null);
  const [navItems, setNavItmes] = useState([]);
  const [selectedSpaId, setSelectedSpaId] = useState("");
  const [userId, setUserId] = useState(null);
  const [region, setRegion] = useState("");
  const [isPearsonAdmin, setPearsonAdmin] = useState(false);
  const [appdrawerList, setAppdrawerList] = useState([])
  const [platformChecked, setPlatformChecked] = useState(false);

  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const isRedirected = query.get('switch');

  const { appcuesData } = useAppcues();
  const history = useHistory();
  let popups = useMemo(() => {
    return {
      errorPopup: {
        setState: (state) => {
          setErrorPopupState({
            ...defaultErrorPopupState,
            onClosePopup: () => setErrorPopupVisibility(false),
            buttonAction: () => setErrorPopupVisibility(false),
            cancelButtonAction: () => setErrorPopupVisibility(false),
            ...state,
          });
        },
        setVisibility: setErrorPopupVisibility,
      },
    };
  }, []);

  const unAuthorizedErrorMessage = useMemo(() => {
    return {
      isOpen: true,
      heading: translate("Sorry!!"),
      info: translate("You are not entitled for this action."),
      subInfo: translate("Please contact Support/Administrator."),
      btnName: translate("Okay, Got it"),
      btnStyle: true,
      image: `${process.env.PUBLIC_URL}/images/error-sorry.svg`,
      onClosePopup: () => setErrorPopupVisibility(false),
      buttonAction: () => setErrorPopupVisibility(false),
      cancelButtonAction: () => setErrorPopupVisibility(false),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function closeToast() {
    setToast(defaultToastObj);
  }

  function closeConfirmPopup() {
    setConfirmPopupState(defaultConfirmPopupState);
  }

  function closeProgressPopupV2() {
    setProgressPopupV2State(defaultProgressPopupV2State);
  }

  function closeProgressPopup() {
    setProgressPopupState(defaultProgressPopupState);
  }

  const userAbilitiesResponse = useNetworkResponse();
  const headerInfoResponse = useNetworkResponse();
  const notificationResponse = useNetworkResponse();
  const pearsonAdminResponse = useNetworkResponse();

  const setToken = useCallback((tokenValue) => {
    setAuthToken(tokenValue);
    setIsTokenExist(true);
  }, []);

  useEffect(async () => {
    setUserIdHeader(userId);
  }, [userId]);

  useEffect(() => {
    if (appcuesData === null || !window.Appcues) return;
    const isAllowed = appcuesData.ALLOWED_ORIGIN.includes(window.location.hostname);
    if (!isAllowed) return
    window.Appcues.track("Login");
  }, [userId, window.Appcues])

  const PMRIDs = ["urn:pearson:gps:productmodelregion:e0fe76ad-bc5a-4dc1-8c7c-caf636e7e2a3",
    "urn:pearson:gps:productmodelregion:9b92df41-f614-408a-8e49-d8a255a7b52a"];

  function fetchOrganizationList() {
    const subscription = getOrgDetailsByUserV2(userId).subscribe(
      (response) => {
        const organisationList = response || [];
        if (organisationList.length) {
          const orgsList = organisationList.reduce((filtered, object) => {
            const pmrs = object.productModelRegions.filter(ele => PMRIDs.includes(ele.id))
            if (pmrs?.length) {
              const pmrIds = pmrs.map(ele => ele.id);
              const newOrgObject = { ...object, productModelRegionDetails: pmrs, productModelRegions: pmrIds };
              filtered.push(newOrgObject);
            }
            return filtered;
          }, []);
          setOrganization(orgsList);
        }
      },
      (error) => {
        console.log('Error ', error)
      }
    );
    return () => subscription.unsubscribe();
  }

  function fetchTierInformation() {
    const subscription = getTierInformation().subscribe(
      (response) => {
        if (response && response.length) localStorage.setItem('tier_info', JSON.stringify(response))
      },
      (error) => {
        console.log('Error ', error)
      }
    );
    return () => subscription.unsubscribe();
  }

  function fetchPearsonAdmin(pmrId, userId) {
    pearsonAdminResponse.dispatch({
      type: "STARTED",
    });
    const subscription = getPearsonAdmin(pmrId, userId).subscribe(
      (response) => {
        setPearsonAdmin(response)
        pearsonAdminResponse.dispatch({
          type: "SUCCESS",
          payload: abilityBuilder(userAbilities),
        });
      },
      (error) => {
        pearsonAdminResponse.dispatch({
          type: "ERROR",
          payload: error,
        });
        console.log('Error ', error)
      }
    );
    return () => subscription.unsubscribe();
  }

  const fetchUserPlatformAccess = () => {
    const subscription = getUserPlatform().subscribe(
      (response) => {
        const hasMatAccess = response && response?.length && response?.filter(data => data && data?.name?.toLowerCase() === MAT_PLATFORM_NAME.toLowerCase());
        if (hasMatAccess.length) {
          localStorage.setItem('isRedirectedToMat', true);
          // window.attachEvent("onmessage", { "isRedirectedToMat": true });
          window.location.href = decodeURIComponent(hasMatAccess[0].launchUrl.replace('&num;', '#')) + '#/application/dashboard?switch=true';
        }
        !platformChecked && setPlatformChecked(true);
      },
      (err) => {
        console.log("platform access err: ", err);
        !platformChecked && setPlatformChecked(true)
      }
    );
    return () => subscription.unsubscribe();
  }

  const getUserRole = useCallback(() => {
    let userRole;
    let isInstructor = userAbilities.can("VIEW", "LESSON_PLAN");
    let isStudent = userAbilities.can("VIEW", "STUDENT_HOME");
    if (isPearsonAdmin) {
      userRole = ADMIN;
    } 
    else {
      if (isStudent && !isInstructor) userRole = LEARNER;
      if(!isStudent && isInstructor) userRole = INSTRUCTOR;
    }
    return userRole;
  },[userAbilities, isPearsonAdmin]);

  useEffect(() => {
    const gtmLoad = window.dataLayer?.length && window.dataLayer?.some(data => data?.event === 'gtm.load');
    const userRole = getUserRole();
    if (window.USERPROFILE != undefined && organization != null && gtmLoad && userRole) {
      window.dataLayer = window.dataLayer || [];
      let userData = window.USERPROFILE
      let googleLoggedInData = {
        person_id: userData.userId, // User Id
        person_id_type: "IES", // User Authentication Type
        person_role_code: userRole, // User’s Role
        login_session_id: window.piSession?.getContextId(), // Login Session Id
        organization_id: organization[0].organizationId, // Organization / Institution Id
        organization_id_type: organization[0].name // Organization Type
      }
      {/** Identify data based on ACTHB-6431 */}
      window.Appcues && window.Appcues.identify(
        userData.userId, // unique, required
         {
           createdAt: Date.now(), // Unix timestamp of user signup date
           role: userRole, // Current user’s role or permissions
          }
       );

      window.dataLayer.push(googleLoggedInData);
    }
  }, [availableSPAs, organization, window.dataLayer]);

  const buildAbility = (ability) => {
    const userAbilities = abilityBuilder(ability);
    setUserAbilities(userAbilities);
    window.GATEWAY = Object.assign(window.GATEWAY || {}, { userAbilities });
  };

  const fetchUserAbilities = (userId, region) => {
    userAbilitiesResponse.dispatch({
      type: "STARTED",
    });
    getUserAbilities(userId, region).subscribe(
      (response) => {
        userAbilitiesResponse.dispatch({
          type: "SUCCESS",
          payload: abilityBuilder(userAbilities),
        });
        buildAbility(response);
      },
      (error) => {
        userAbilitiesResponse.dispatch({
          type: "ERROR",
          payload: error,
        });
        if (error && error.data && error.data.status >= 500) {
          buildAbility([]);
        } else if (error && error.data && error.data.status === 401) {
          iesSession.logout();
        } else if (error && error.data && error.data.status === 404) {
          buildAbility([]);
        } else if (error && error.data && error.data.status === 403) {
          setWrapperLevelErrorPage(getErrorComponent(403));
        }
      }
    );
  };

  useEffect(() => {
    if (!userId || !isTokenExist) return;
    
    if (isRedirected) {
      localStorage.setItem('isRedirectedToMat', true);
    }

    if (!platformChecked) {
      if (!localStorage.getItem('isRedirectedToMat') && !localStorage.getItem('isRedirectedToAH') && !isRedirected) {
        fetchUserPlatformAccess();
        return;
      }
    }

    // if (window.addEventListener) {
    //   window.addEventListener("message", function (e) {
    //     console.log("I am triggered..");
    //     localStorage.setItem('isRedirectedToMat', true);
    //     fetchUserPlatformAccess();
    //   },false);
    // } else {
    //   if (!localStorage.getItem('isRedirectedToMat') && !localStorage.getItem('isRedirectedToAH')) {
    //     fetchUserPlatformAccess();
    //   }
    // }
    fetchOrganizationList();
    fetchTierInformation();
    fetchAppdrawerList(userId)
    fetchPearsonAdmin(PMRIDs[0], userId);
    headerInfoResponse.dispatch({
      type: "STARTED",
    });
    const subscription = getHeaderInfo().subscribe(
      (response) => {
        headerInfoResponse.dispatch({
          type: "SUCCESS",
          payload: response,
        });
        sessionStorage.setItem("regionDetail", JSON.stringify(response));
        fetchUserAbilities(userId, response.regionId);
        setRegion(response.regionId);
      },
      (error) => {
        headerInfoResponse.dispatch({
          type: "ERROR",
          payload: error,
        });
        if (error && error.data && error.data.status >= 500) {
          setHeaderDetails({
            name: "Pearson",
            description: "pearson global gateway",
            headerImageUrl: `${process.env.PUBLIC_URL}/images/Pearson-everest-logo.svg`,
          });
        } else if (error && error.data && error.data.status === 401) {
          iesSession.logout();
        } else if (error && error.data && error.data.status === 404) {
          console.log("error 404 for region");
          setWrapperLevelErrorPage(getErrorComponent(404));
        } else if (error && error.status === 403) {
          console.log("error 403 for region");
          // if (isRedeemCodePage) return;
          setWrapperLevelErrorPage(getErrorComponent(403));
        }
        console.log("Error in API", error);
      }
    );

    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, isTokenExist, platformChecked]);

  useEffect(() => {
    // for fetching unread notifications
    if (!userId || !isTokenExist) return;
    notificationResponse.dispatch({
      type: "STARTED",
    });
    const subscription = getNotifications().subscribe(
      (response) => {
        notificationResponse.dispatch({
          type: "SUCCESS",
          payload: response,
        });
        setNotifications(response)
      },
      (error) => {
        notificationResponse.dispatch({
          type: "ERROR",
          payload: error,
        });
        console.log("Error in API", error);
      }
    );

    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, isTokenExist]);

  useEffect(() => {
    const loginTypeVal = sessionStorage.getItem("LOGINTYPE");
    const loginParams = (loginTypeVal) ? { queryParams: { loginType: loginTypeVal } } : {};
    const iesSubscription = IESAuthUtilV2.load({
      ...IESConfig,
      callbackURL: window.location.href,
      domain: window.location.hostname,
      ...loginParams
    }, IESOptions).pipe(map(iesdata => { window.IES = iesdata; console.log("Login Map", iesdata.getUserId()); return (iesdata) }), delay(3000)).subscribe((session) => {

      setIesSession(session);

      if (!window.IES.getUserId() && isBaseUrl()) {
        console.log("Login Failed", window.IES.getUserId())
        window.location.assign(`${window.location.origin}${window.location.pathname}#/login`);
      } else {
        localStorage.setItem('preLoginUserId', window.IES.getUserId());
        //localStorage.setItem('isRedirectedToAH', true);
        console.log("Login Success", window.IES)
        session.login();
        setUserId(session?.getUserId());
      }


      setUserId(session.getUserId());
      setToken(session.getPiSessionObj().currentToken());
      session.onRefresh((refreshEvent) => {
        // setToken(refreshEvent.data);
        refreshEvent.data && setUserId(session.getUserId());
        refreshEvent.data && setToken(refreshEvent.data);

        if (!refreshEvent.data) {
          console.log("Login Failed")
          window.location.assign(`${window.location.origin}${window.location.pathname}#/login`);
        }

      });
    });

    return () => iesSubscription.unsubscribe();
    // no need to call ies again if setters change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isBaseUrl = () => {
    // https://qa.pet.ift.pearson-intl.com/activehub/index.html?iesError=login_required&iesErrorDescription=Login+required#/
    // https://stg.pet.ift.pearson-intl.com/activehub/index.html?iesError=login_required&iesErrorDescription=Login+required#/
    console.log('window.location.href', window.location.href);
    let environment = ['dev', 'qa', 'stg', 'perf']
    let gethostname = window.location.hostname.split(".")[0];
    let domainBasedUrl = environment.includes(gethostname) ? `https://${gethostname}.pet.ift.pearson-intl.com/?iesError=login_required&iesErrorDescription=Login+required#/` : 'https://activehub.pearson.com/#/';
    return (window.location.href === domainBasedUrl || window.location.href === "http://gateway.pearson.com:3009/#/");
  }

  useEffect(() => {
    console.log("Process Env", process.env)
    if (!iesSession) return
    const redirectToPreLogin = () => {
      window.PRELOGIN_URL = '';
      localStorage.clear();
      window.location.assign(`${window.location.origin}${window.location.pathname}#/login`);
    }
    iesSession.onLogout(redirectToPreLogin);
  }, [iesSession])


  const fetchSpasInRegion = useCallback((ability) => {
    if (!region) return;
    return getSPAsInRegion(region).subscribe(
      response => {
        setAvailableSPAs(response);
        const lessonSpaId = response.filter(data =>
          data.description === 'lesson-plan'
        )
        const assessmentsSpaId = response.filter(data =>
          data.description === 'assessments'
        )
        const insightsSpaId = response.filter(data =>
          data.description === 'insights'
        )
        const studentSpaId = response.filter(data =>
          data.description === 'student-home'
        )
        sessionStorage.setItem("lessonSpaId", encodeURIComponent(lessonSpaId[0].spaId) || null);
        sessionStorage.setItem("assessmentsSpaId", encodeURIComponent(assessmentsSpaId[0].spaId) || null);
        sessionStorage.setItem("insightsSpaId", encodeURIComponent(insightsSpaId[0].spaId) || null);
        sessionStorage.setItem("studentSpaId", encodeURIComponent(studentSpaId[0].spaId) || null);
      },
      error => {
        if (error && error.data && error.data.status >= 500) {
          setSpaDetailsList([])
        } else if (error && error.data && error.data.status === 401) {
          iesSession.logout();
        } else if (error && error.data && error.data.status === 404) {
          setSpaDetailsList([])
          setWrapperLevelErrorPage(getErrorComponent(404));
        } else if (error && error.status === 403) {
          let response = [];
          setSpaDetailsList([]);
          setWrapperLevelErrorPage(getErrorComponent(403));
        }
        console.log('Error in API', error)
      },
    );
  }, [region, setSpaDetailsList, iesSession, setSelectedSpaId])

  const setAllowedSPAsInRegion = useCallback((ability) => {
    if (availableSPAs.length === 0) return;
    const allowedSPA = availableSPAs.filter(spa => {
      const { action, subject } = spa.ability;
      return action ? ability.can(action, subject) : false;
    });

    setSpaDetailsList(allowedSPA)
    const defaultSpaId = allowedSPA.filter(spa => {
      return (spa.spaId === urlApplicationId) ? spa : false
    });

    if (defaultSpaId[0]) {
      setSelectedSpaId(defaultSpaId[0]?.spaId);
    } else {
      /**
       * if logged in user is student then load student spa 
       * or load home page
       */
      if (allowedSPA[0]?.description == "student-home") {
        setSelectedSpaId(allowedSPA[0]?.spaId);
      } else {
        setSelectedSpaId('dashboard');
      }
    }
  }, [availableSPAs, setSpaDetailsList, setSelectedSpaId])

  const fetchAppdrawerList = ((userId) => {
    const subscription = getAppdrawer(userId).subscribe(
      (response) => {
        setAppdrawerList(response)
      },
      (error) => {
        if (error && error.data && error.data.status >= 500) {
          setAppdrawerList([]);
        } else if (error && error.data && error.data.status === 401) {
          iesSession.logout();
        } else if (error && error.data && error.data.status === 404) {
          setAppdrawerList([]);
        } else if (error && error.data && error.data.status === 403) {
          setWrapperLevelErrorPage(getErrorComponent(403));
        }
      }
    );
    return () => subscription.unsubscribe();
  })

  const appContextValue = useMemo(() => {
    return {
      headerDetails,
      setHeaderDetails,
      footerDetails,
      setFooterDetails,
      region,
      setRegion,
      locale,
      iesSession,
      userName,
      translate,
      translateHTML,
      setTranslate,
      wrapperLevelErrorPage,
      setWrapperLevelErrorPage,
      setUserName,
      defaultSpaId,
      selectedSpaId,
      setSelectedSpaId,
      userId,
      spaDetailsList,
      closeConfirmPopup,
      closeProgressPopupV2,
      closeProgressPopup,
      setProgressPopupState,
      setProgressPopupV2State,
      setConfirmPopupState,
      setToast,
      unAuthorizedErrorMessage,
      fetchSpasInRegion,
      entities,
      notifications,
      setNotifications,
      availableSPAs,
      setAllowedSPAsInRegion,
      setUrlApplicationId,
      isOnlyStudentHomeSpa,
      setIsOnlyStudentHomeSpa,
      navItems,
      setNavItmes,
      organization,
      isPearsonAdmin,
      appdrawerList,
      ...popups,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    headerDetails,
    footerDetails,
    region,
    iesSession,
    userName,
    setUserName,
    defaultSpaId,
    selectedSpaId,
    userId,
    spaDetailsList,
    popups,
    notifications,
    availableSPAs,
    setAllowedSPAsInRegion,
    setUrlApplicationId,
    wrapperLevelErrorPage,
    isOnlyStudentHomeSpa,
    setIsOnlyStudentHomeSpa,
    navItems,
    organization,
    isPearsonAdmin,
    appdrawerList
  ]);

  if (!window.piSession || !userId) {
    return <Loader />
  }
  else if (!isTokenExist || !userId || userAbilitiesResponse.isLoading || headerInfoResponse.isLoading || pearsonAdminResponse.isLoading) {
    return <ExploreLessonsLoader />;
  }
  return (
    <AppContext.Provider value={appContextValue}>
      <AbilityContext.Provider value={userAbilities}>
        <Suspense fallback={<h1> error </h1>}>
          <ToastMsg
            title={toast.title}
            show={toast.show}
            msg={toast.msg}
            status={toast.status}
            onClose={() => closeToast()}
            styleClass={toast.styleClass}
            delay={toast.delay}
            autoHide={toast.autoHide}
            toastImg={toast.toastImg}
          />
          <ProgressPopUp
            isOpen={progressPopupState.isOpen}
            waitingMsg={progressPopupState.waitingMsg}
            done={progressPopupState.done}
            itemName={progressPopupState.itemName}
            type={progressPopupState.type}
            successMsg={progressPopupState.successMsg}
            animationDuration={progressPopupState.animationDuration}
            onSuccess={progressPopupState.onSuccess}
            status={progressPopupState.status}
            loadingImage={progressPopupState.loadingImage}
            headerContent={progressPopupState.headerContent}
            footerCustomElement={progressPopupState.footerCustomElement}
          />
          <ProgressPopUpV2
            isOpen={progressPopupV2State.isOpen}
            animationDuration={progressPopupV2State.animationDuration}
            loadingBg={progressPopupV2State.loadingBg}
            info={progressPopupV2State.info}
            subInfo={progressPopupV2State.subInfo}
            progressDone={progressPopupV2State.progressDone}
            onProgressBarFilled={progressPopupV2State.onProgressBarFilled}
            progressErrorInfo={progressPopupV2State.progressErrorInfo}
            progressInfo={progressPopupV2State.progressInfo}
            showProgressBar={progressPopupV2State.showProgressBar}
            propgresstypeIcon={progressPopupV2State.propgresstypeIcon}
            customFooter={progressPopupV2State.customFooter}
            footerClass={progressPopupV2State.footerClass}
            isCloseBtn={progressPopupV2State.isCloseBtn}
            closePopup={progressPopupV2State.closePopup}
            showSuccessImage={progressPopupV2State.showSuccessImage}
          />
          <ErrorPopUp
            isOpen={errorPopupVisibility}
            heading={errorPopupState.heading}
            info={errorPopupState.info}
            btnName={errorPopupState.btnName}
            buttonAction={errorPopupState.buttonAction}
            btnStyle={errorPopupState.btnStyle}
            cancelBtnName={errorPopupState.cancelBtnName}
            cancelButtonAction={errorPopupState.cancelButtonAction}
            cancelBtnStyle={errorPopupState.cancelBtnStyle}
            image={errorPopupState.image}
            closePopup={errorPopupState.onClosePopup}
            subInfo={errorPopupState.subInfo}
            infoClassName={errorPopupState.infoClassName}
            subInfoClassName={errorPopupState.subInfoClassName}
            renderExtraElement={errorPopupState.renderExtraElement}
          />
          <ConfirmPopUp
            isModalshow={confirmPopupState.isModalshow}
            title={confirmPopupState.title}
            dialogMsg={confirmPopupState.dialogMsg}
            positiveButtonText={confirmPopupState.positiveButtonText}
            positiveButtonHandle={confirmPopupState.positiveButtonHandle}
            negativeButtonText={confirmPopupState.negativeButtonText}
            negativeButtonHandle={confirmPopupState.negativeButtonHandle}
            positiveButtonStyleClass={
              confirmPopupState.positiveButtonStyleClass
            }
            negativeButtonStyleClass={
              confirmPopupState.negativeButtonStyleClass
            }
            subInfo={confirmPopupState.subInfo}
          />
          <Callback />
        </Suspense>
      </AbilityContext.Provider>
    </AppContext.Provider>
  );
};

export default App;
